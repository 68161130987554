.graph-container {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 50px repeat(6, 1fr);
    grid-gap: 20px;
    // height: 300px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 1rem;
    ul {
        height: 100%;
        width: 100%;
        list-style: none;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        border-right: 1px solid #ccc;
    }
    li:last-child {
        margin-bottom: 1rem;
    }
    .graph-container__indicator {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
}
.graph-container__item {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 100%;

    p:first-of-type {
        color: #F92C79;
        font-weight: bold;
        font-size: 24px;
    }
    
    // p:last-child {
    //     position: absolute;
    //     top: 310px;
    //     text-align: center;
    // }
    // #alder {
    //     position: absolute;
    //     top: 310px;
    //     text-align: center;
    // }
}
.graph-container__item__column {
    background-color: #F92C79;
    width: 50%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.graph-container li,
.graph-container__item p
 {
    color: black;
}

.ages {
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 50px repeat(6, 1fr);
    grid-gap: 20px;
    justify-items: center;
    margin-bottom: 3rem;
    @media (max-width: 700px) {
        display: none;
    }

    p {
    text-align: center;
    align-self: end;
    }
}