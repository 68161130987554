.dropdown {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;

    #dropdown--heading {
        margin-top: 1.5rem;
    }

    .dropdown__top-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: fit-content;
        padding: 1rem 0.5rem;
        background-color: map-get($colors, myYellow );
        border: 2px solid map-get($colors, text-primary )
    }

    .dropdown__top-container__arrow {
        position: absolute;
        right: 10px;
        font-size: 25px;
        cursor: pointer;
    }
    .dropdown__top-container__arrow :hover {
        transition: all 0.2s;
        transform: translateY(5px);
    }
    .dropdown__container {
        font-size: map-get($font-size, main-heading);
        background-color: map-get($colors, myYellow );
        padding: 2rem 1rem;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        margin-bottom: 2rem;
    }
    .heading--centered {
        text-align: center;
        margin-bottom: 2rem;
    }

    @keyframes append-animate {
        from {
            transform: scaleY(0);
            opacity: 0;
        }
        to {
            transform: scaleY(1);
            opacity: 1;
        }
    }
    
    .dropdown__container {
        transform-origin: 50% 0;
        animation: append-animate .3s linear;
    }
}
