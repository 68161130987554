.contentBlock {
    position: relative;
    margin: 3rem 0rem;
    max-width: 90%;
    display: flex;
    height: fit-content;
    h2 {
        font-size: map-get($font-size, main-heading);
        font-weight: 800;
        font-style: italic;
        margin-bottom: 1rem;
        color: map-get($colors, text-primary);
    }
    .contentBlock__text {
        background-color: map-get($colors, myYellow );
        width: 55%;
        padding: 2rem 4rem 2rem 2rem;
        p {
            font-family: map-get($fonts, body);
            font-size: 18px;
            margin-bottom: 2rem;
        }
    }
    a {
        text-decoration: none;
    }
    .contentBlock__image-container {
        position: absolute;
        top: 10%;
        left: 50%;
        width: 55%;
        height: 80%;
        box-shadow: 15px 15px 0px map-get($colors, myGreen);
        
        img {
            box-shadow: 0px 0px 5px rgb(107, 107, 107);
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.contentBlock-merch {
    position: relative;
    margin: 3rem auto;
    max-width: 90%;
    display: flex;
    flex-direction: row-reverse;
    height: fit-content;
    h2 {
        font-size: map-get($font-size, main-heading);
        margin-bottom: 1rem;
    }
    .contentBlock-merch__text {
        width: 55%;
        background-color: map-get($colors, myYellow );
        border-radius: 5px;
        padding: 2rem 4rem 2rem 6rem;
        p {
            font-family: map-get($fonts, body);
            font-size: 18px;
            margin-bottom: 2rem;
        }
    }
    a {
        text-decoration: none;
    }
    .contentBlock-merch__icon-container {
        display: flex;
        list-style: none;
        margin-bottom: 2rem;
    }
    .contentBlock-merch__image-container {
        box-shadow: 15px 15px 0px map-get($colors, myGreen);
        position: absolute;
        top: 10%;
        left: 0%;
        width: 50%;
        height: 80%;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            display: block;
        }
    }
}

.contentBlock-merch__text__p {
    font-size: 20px !important;
}

@media (max-width: 960px) {
    .contentBlock, .contentBlock-merch {
        flex-direction: column !important;
        width: 100% !important;
    }
    .contentBlock__text, .contentBlock-merch__text {
        width: 100% !important;
        padding: 2rem !important;
    }
    .contentBlock__image-container, .contentBlock-merch__image-container {
        position: static !important;
        width: 100% !important;
        margin-top: 1rem;
    }
    .contentBlock-merch__image-container img {
        max-height: 450px;
    }
}