.show-container {
    width: 80%;
    margin: 0 auto;
    min-height: 100vh;
    h1 {
        color: map-get($colors, text-primary );
        @media (max-width: 500px) {
            font-size: 34px;
        }
    }
    h2 {
        margin: 4rem 0rem 3rem 0rem;
        color: map-get($colors, text-primary);
        font-style: italic;
        text-align: center;
        font-weight: 800;
    }
}
.show-container__image-container {
    position: relative;
    width: 100%;
    img {
        width: 100%;
        height: 500px;
        object-fit: cover;
    }
}
.show-container__image-container__link {
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: rgba(255, 255, 255, 0.537);
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    svg {
        height: 30px;
    }
}
.show-container__image-container__link:hover {
    background-color: white;
}

.show-container__text-container {
    p {
        margin-top: 1rem;
        font-family: map-get($fonts, body);
        font-size: map-get($font-size, body);
        line-height: 1.75rem;
        color: black;
    }
    a {
        max-width: 300px;
        margin: 1.5rem auto;
        border: 2px solid #001361;
        border-radius: 3px;
        padding: 0.5rem 0.75rem;
        text-decoration: none;
        transition: all 0.2s;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: map-get($shadows, dark);
    }
    a:hover {
        scale: 1.1;
        cursor: pointer;
        -webkit-transform: scale(1.1);
    }
    svg {
        font-size: 25px;
    }
    .fa-instagram {
        margin-right: 0.5rem;
    }
    .fa-tiktok {
        margin-left: 0.5rem;
    }
    .show-container__text-container--icon {
        height: 35px;
    }
    .show-container__text-container__tiktok-container {
        margin: 2rem 0rem;
        background-color: map-get($colors, backgroundPurple );
        display: flex;
        align-items: center;
        padding: 0.65rem;
        h6 {
            margin: 0rem 0rem 0rem 1rem;
            color: white;
        }
        img {
            filter: invert(94%) sepia(100%) saturate(0%) hue-rotate(17deg) brightness(108%) contrast(102%);
        }
    }
    .show-container__text-container__instagram-container {
        margin: 2rem 0rem;
        background-color: map-get($colors, myGreen );
        display: flex;
        align-items: center;
        padding: 0.65rem;
        h6 {
            margin: 0rem 0rem 0rem 1rem;
            color: white;
        }
        img {
            filter: invert(94%) sepia(100%) saturate(0%) hue-rotate(17deg) brightness(108%) contrast(102%);
        }
    }
}

.fa-youtube {
    path {
        color: red;
    }
} 
.show-container__text-container--some {
    font-size: 30px !important;
    font-weight: 600;
    text-align: center;
    margin: 2.5rem 0rem !important;
    font-style: italic;
    color: #743292 !important;
    .fa-tiktok {
        path {
            color: #743292;
        }
    }
    .fa-instagram {
        path {
            color: #743292;
        }
    }
}
.show-container__demography-container {
    padding: 0.25rem;
    margin-bottom: 5rem;
    p {
        font-family: map-get($fonts, body);
        font-size: map-get($font-size, body);
    }
}
.demography-container__youtube-container {
    display: flex;
    padding: 1rem;
    justify-content: space-evenly;
    align-items: center;
    background-color: #F92C79;
    img {
        width: 135px;
        height: 40px;
    }
    p, strong {
        color: white;
    }
}
.youtube-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
}
#demography {
    text-align: center;
}
#youtube-logo {
    width: 120px;
    margin: 0 auto;
}

.demography-container__youtube-container p {
    margin: 0rem 1rem 0rem 1rem;
}

@media (max-width: 960px) {
    .show-container h1 {
        margin-top: 2.5rem;
    }
}

@media (max-width: 900px) {
    .demography-container__youtube-container {
        p {
            margin-top: 0.75rem;
        }
        flex-direction: column;
    }
}

@media (max-width: 700px) {
    .show-container__image-container {
        height: 200px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    #demography,
    .graph-container {
        display: none !important;
    }
    .show-container__text-container a {
        margin: 2.5rem auto;
    }
    .demography-container__youtube-container p{
        margin: 0.5rem auto;
    }
   
}