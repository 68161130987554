* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Fira sans';
    color: map-get($colors, text-primary);
}
html {
    height: 100%;
}
body {
    background-color: map-get($colors, backgroundNew);
}
#root {
    min-height: 100vh;
}

h1 {
    font-size: map-get($font-size, h1);
}
h2 {
    font-size: map-get($font-size, h2);
}
h3 {
    font-size: map-get($font-size, h3);
}
h4 {
    font-size: map-get($font-size, h4);
}
h5 {
    font-size: map-get($font-size, h5);
}
h6 {
    font-size: map-get($font-size, h6);
}
p {
    font-size: map-get($font-size, body);
}