.cta-1 {
    background-color: none;
    border: 4px solid map-get($colors, text-primary);
    border-radius: 15px;
    font-family: map-get($fonts, heading);
    font-style: italic;
    font-weight: 800;
    color: map-get($colors, text-primary);
    width: 200px;
    height: 55px;
    margin: 3rem auto;
    padding: 1rem;
    font-size: 28px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 8px 8px 0px map-get($colors, myGreen );

    p{
        font-size: 28px !important;
        margin: 0 !important;
    }
}

.cta-1:hover {
    background-color: map-get($colors, myGreen );
    box-shadow: none;
}