.om-loff {
    max-width: 800px;
    margin: 0 auto;
    min-height: 100vh;
    p {
        margin-bottom: 1.5rem;
    }
    img {
        width: 100%;
        margin-bottom: 1.5rem;
    }
}
.om-loff--bold-center {
    font-weight: bold;
    text-align: center;
    margin: 3rem 0rem !important;
}