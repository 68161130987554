.slick-slider {
    margin: 2rem 0rem;
    height: 600px;
    img {
        width: 100%;
        height: 600px;
        object-fit: cover;
    }
}
.slick-arrow::before {
    font-size: 30px;
}
.slick-prev {
    left: 25px;
    z-index: 10;
}
.slick-next {
    right: 25px;
    z-index: 10;
}

@media (max-width: 600px) {
    .slick-slider,
    .slick-list,
    .slick-track {
        height: 350px;
    }
    .carousel-image-container {
        height: 350px;
        img {
            height: 100%;
        }
    }
}