.wrapper {
    max-width: 90%;
    width: 1200px;
    margin: 0 auto;
}
h1 {
    text-align: center;
    margin: 1rem 0rem;
    font-style: italic;
    font-weight: 900;
    font-size: map-get($font-size, h1);
}
.wrapper--flex-row {
    display: flex;
    align-items: center;
    height: 100%;
}
.wrapper--flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
.contentBlock-wrapper {
    width: 100%;
    padding: 0.5rem 0rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    min-height: 650px;
}
.contentBlock-merch-wrapper {
    width: 100%;
    padding: 0.5rem 0rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.some-wrapper {
    padding: 2rem 0rem;
    background-color: map-get($colors, backgroundPurple );
    width: 100%;
    box-shadow: 0px 0px 5px rgb(169, 168, 168);
}
.some-wrapper_top-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 90%;
    width: 1200px;
    margin: 1rem auto;
    padding: 2rem 0rem;
    border-radius: 3px;
    font-family: "Fira sans";
    h2 {
        color: map-get($colors, text-primary);
        font-family: map-get($fonts, heading);
        font-style: italic;
    }
    p {
        color: map-get($colors, text-primary);
        margin-top: 1rem;
        max-width: 90%;
        text-align: center;
        font-family: map-get($fonts, heading);
    }
    li {
        transition: all 0.2s;
        transform-style: preserve-3d;
        -moz-transform-style: preserve-3d;
        -webkit-transition: all 0.2s;
    }
    // li:hover {
    //     scale: 1.1;
    //     cursor: pointer;
    //     -webkit-transform: scale(1.1);
    // }
}
.some-wrapper__icon-container {
    display: flex;
    list-style: none;
    margin: 2rem 0rem 0.5rem 0rem;
    img {
        width: 30px;
        height: 30px;
    }
}
.some-container {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    img {
        object-fit: cover;
        height: 220px;
        width: 100%;
    }
}

@media (max-width: 530px) {
    .some-wrapper__icon-container {
        width: 100%;
        justify-content: space-between;
        padding: 0rem 0.5rem;
    }
    .some-wrapper__icon-container a {
        margin: 0;
    }
}