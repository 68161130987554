.series-container {
    display: flex;
    width: 100%;
    // border: 1px solid #ccc;
    background-color: map-get($colors, myYellow );
    margin: 3rem 0rem;
    padding: 0.25rem;
    box-shadow: 5px 5px 0px map-get($colors, backgroundPurple );
}
.series-container__image-container {
    width: 50%;
    img {
        display: block;
        height: 275px;
        width: 100%;
        object-fit: cover;
    }
}
.series-container__text-container {
    width: 75%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    p {
        color: rgb(0, 19, 97);
        margin-top: 1rem;
        font-family: map-get($fonts, body);
        font-size: map-get($font-size, body );
    }
    h2 {
        font-style: italic;
        font-weight: 800;
    }
}
.series-container__text-container__link-container {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    .series-container__youtube {
        width: 35px;
    }
    #youtube-link {
        border: none;
    }
    .youtube {
        path {
            color: red;
        }
    }
}
.series-container__text-container__link-container__link {
    border: 3px solid map-get($colors, text-primary);
    border-radius: 5px;
    padding: 0.5rem 0.75rem;
    text-decoration: none;
    transition: all 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s;
    -webkit-transition: all 0.2s;
    svg {
        margin-left: 0.5rem;
    }
 
}
#linkwrapper {
    margin: 0;
    display: inline;
}
.series-container__text-container__link-container__link:last-child {
    svg {
        font-size: 20px;
    }
}
.series-container__text-container__link-container__link:hover {
    scale: 1.05;
    transform: scale(1.05);
    -webkit-transform: scale(1.05);
    cursor: pointer;
}

@media (max-width: 780px) {
    .series-container {
        flex-direction: column;
    }
    .series-container__image-container {
        width: 100%;
        img {
            height: auto;
        }
    }
    .series-container__text-container {
        width: 100%;
    }
    .series-container__text-container__link-container {
        margin: 1rem 0rem;
        flex-direction: column;
    }
    .series-container__text-container__link-container__link {
        margin-bottom: 0.75rem;
    }
    .series-container__text-container__link-container__link:hover {
        scale: 1;
        transform: scale(1);
        -webkit-transform: scale(1);
    }
}