footer {
    position: sticky;
    top: 100%;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: fit-content;
    box-shadow: 0px 0px 10px rgb(199, 199, 199);
    background-color: map-get($colors, backgroundPurple );
    margin-top: 2rem;
    padding: 1rem 0rem;
}
footer ul {
    display: flex;
    list-style: none;
    justify-content: space-evenly;
    border-radius: 3px;
    img {
        width: 30px;
    }
}
footer li {
    padding: 0.75rem;
}