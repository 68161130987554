nav {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 110px;
    // box-shadow: 0px 0px 10px rgb(167, 167, 167);
}
nav ul {
    display: flex;
    list-style: none;
}
.logoContainer {
    height: 100%;
    margin: 0 auto;
}
.logoContainer img {
    height: 100%;
}
.nav__link-container,
.nav__icon-container {
    width: 355px;
    @media (max-width: 440px) {
        width: 100%;
    }
}
.nav__icon-container {
    justify-content: flex-end;
}
.nav__link-container a {
margin-right: 1rem;
text-decoration: none;
font-family: map-get($fonts, heading);
font-size: 18px;
font-weight: bold;
font-style: italic;
}
.nav__link-container a:hover {
    text-decoration: underline;
}
.nav__icon-container li a{
    font-size: 1.5rem;
    // width: 55px;
    margin-right: 1.25rem;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -webkit-transition: scale 0.15s;
    transform-origin: left;
}
.nav__icon-container a {
    height: 30px;
}
.nav__icon-container li a img {
    width: 30px;
    height: 30px;
   
    transition: all 0.2s;
}
.nav__icon-container li a img:hover {
    scale: 1.1;
    cursor: pointer;
    -webkit-transform: scale(1.05);
}

@media (max-width: 960px) {
    .logoContainer {
        height: 50%;
        order: -1;
    }
    .nav__link-container,
    .nav__icon-container {
        li {
            margin: 0;
        }
        a {
            margin: 0 !important;
        }
        justify-content: space-between;
    }
    .nav__link-container {
        margin: 1.25rem 0rem;
    }
    
    nav {
        height: 200px;
        .wrapper {
            flex-direction: column;
            height: 100%;
        }
    }
}