.slick-slider {
  margin: 2rem 0rem;
  height: 600px;
}
.slick-slider img {
  width: 100%;
  height: 600px;
  object-fit: cover;
}

.slick-arrow::before {
  font-size: 30px;
}

.slick-prev {
  left: 25px;
  z-index: 10;
}

.slick-next {
  right: 25px;
  z-index: 10;
}

@media (max-width: 600px) {
  .slick-slider,
  .slick-list,
  .slick-track {
    height: 350px;
  }
  .carousel-image-container {
    height: 350px;
  }
  .carousel-image-container img {
    height: 100%;
  }
}
footer {
  position: sticky;
  top: 100%;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: fit-content;
  box-shadow: 0px 0px 10px rgb(199, 199, 199);
  background-color: #C995C8;
  margin-top: 2rem;
  padding: 1rem 0rem;
}

footer ul {
  display: flex;
  list-style: none;
  justify-content: space-evenly;
  border-radius: 3px;
}
footer ul img {
  width: 30px;
}

footer li {
  padding: 0.75rem;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Fira sans";
  color: #1E103B;
}

html {
  height: 100%;
}

body {
  background-color: #F8FCEF;
}

#root {
  min-height: 100vh;
}

h1 {
  font-size: 42px;
}

h2 {
  font-size: 36px;
}

h3 {
  font-size: 32px;
}

h4 {
  font-size: 28px;
}

h5 {
  font-size: 24px;
}

h6 {
  font-size: 19px;
}

p {
  font-size: 18px;
}

.contentBlock {
  position: relative;
  margin: 3rem 0rem;
  max-width: 90%;
  display: flex;
  height: fit-content;
}
.contentBlock h2 {
  font-weight: 800;
  font-style: italic;
  margin-bottom: 1rem;
  color: #1E103B;
}
.contentBlock .contentBlock__text {
  background-color: #FFF24D;
  width: 55%;
  padding: 2rem 4rem 2rem 2rem;
}
.contentBlock .contentBlock__text p {
  font-family: "Fira sans";
  font-size: 18px;
  margin-bottom: 2rem;
}
.contentBlock a {
  text-decoration: none;
}
.contentBlock .contentBlock__image-container {
  position: absolute;
  top: 10%;
  left: 50%;
  width: 55%;
  height: 80%;
  box-shadow: 15px 15px 0px #30D197;
}
.contentBlock .contentBlock__image-container img {
  box-shadow: 0px 0px 5px rgb(107, 107, 107);
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contentBlock-merch {
  position: relative;
  margin: 3rem auto;
  max-width: 90%;
  display: flex;
  flex-direction: row-reverse;
  height: fit-content;
}
.contentBlock-merch h2 {
  margin-bottom: 1rem;
}
.contentBlock-merch .contentBlock-merch__text {
  width: 55%;
  background-color: #FFF24D;
  border-radius: 5px;
  padding: 2rem 4rem 2rem 6rem;
}
.contentBlock-merch .contentBlock-merch__text p {
  font-family: "Fira sans";
  font-size: 18px;
  margin-bottom: 2rem;
}
.contentBlock-merch a {
  text-decoration: none;
}
.contentBlock-merch .contentBlock-merch__icon-container {
  display: flex;
  list-style: none;
  margin-bottom: 2rem;
}
.contentBlock-merch .contentBlock-merch__image-container {
  box-shadow: 15px 15px 0px #30D197;
  position: absolute;
  top: 10%;
  left: 0%;
  width: 50%;
  height: 80%;
}
.contentBlock-merch .contentBlock-merch__image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.contentBlock-merch__text__p {
  font-size: 20px !important;
}

@media (max-width: 960px) {
  .contentBlock, .contentBlock-merch {
    flex-direction: column !important;
    width: 100% !important;
  }
  .contentBlock__text, .contentBlock-merch__text {
    width: 100% !important;
    padding: 2rem !important;
  }
  .contentBlock__image-container, .contentBlock-merch__image-container {
    position: static !important;
    width: 100% !important;
    margin-top: 1rem;
  }
  .contentBlock-merch__image-container img {
    max-height: 450px;
  }
}
.cta-1 {
  background-color: none;
  border: 4px solid #1E103B;
  border-radius: 15px;
  font-family: "Fira sans";
  font-style: italic;
  font-weight: 800;
  color: #1E103B;
  width: 200px;
  height: 55px;
  margin: 3rem auto;
  padding: 1rem;
  font-size: 28px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 8px 8px 0px #30D197;
}
.cta-1 p {
  font-size: 28px !important;
  margin: 0 !important;
}

.cta-1:hover {
  background-color: #30D197;
  box-shadow: none;
}

.bassene-container {
  display: grid;
  grid-template-rows: auto auto auto;
  grid-template-columns: 1fr 1fr;
  gap: 22px;
}

.subheading {
  text-align: center;
  font-style: italic;
  margin-bottom: 1rem;
}

.employeeCard {
  width: 100%;
  margin: 2rem 0rem;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  box-shadow: 5px 5px 0px #C995C8;
  background-color: #FFF24D;
}

.employeeCard__text-container {
  display: flex;
  flex-direction: column;
}
.employeeCard__text-container h4 {
  margin: 0.5rem 0rem;
}
.employeeCard__text-container h5 {
  margin-bottom: 0.5rem;
  font-style: italic;
  font-weight: 500;
}
.employeeCard__text-container p {
  font-family: "Fira sans";
  font-weight: 500;
  margin-bottom: 1rem;
}

.employeeCard__image-container {
  height: 60%;
  height: 400px;
}
.employeeCard__image-container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: 0px -80px;
}

.employeeCard__social-container {
  display: flex;
  margin-top: auto;
}

.employeeCard__social-container__icon {
  height: 30px;
  margin-right: 1rem;
  transition: all 0.2s;
}

.employeeCard__social-container__icon:hover {
  scale: 1.1;
}

@media (max-width: 970px) {
  .bassene-container {
    grid-auto-rows: auto !important;
    grid-template-columns: 1fr;
  }
  .employeeCard {
    height: fit-content;
    max-width: 550px;
    margin: 0 auto;
  }
  .employeeCard__text-container {
    margin-bottom: 1rem;
  }
}
@media screen and (max-width: 750px) {
  .bassene-container {
    grid-template-rows: auto;
    grid-template-columns: 1fr;
  }
}
nav {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 110px;
}

nav ul {
  display: flex;
  list-style: none;
}

.logoContainer {
  height: 100%;
  margin: 0 auto;
}

.logoContainer img {
  height: 100%;
}

.nav__link-container,
.nav__icon-container {
  width: 355px;
}
@media (max-width: 440px) {
  .nav__link-container,
  .nav__icon-container {
    width: 100%;
  }
}

.nav__icon-container {
  justify-content: flex-end;
}

.nav__link-container a {
  margin-right: 1rem;
  text-decoration: none;
  font-family: "Fira sans";
  font-size: 18px;
  font-weight: bold;
  font-style: italic;
}

.nav__link-container a:hover {
  text-decoration: underline;
}

.nav__icon-container li a {
  font-size: 1.5rem;
  margin-right: 1.25rem;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -webkit-transition: scale 0.15s;
  transform-origin: left;
}

.nav__icon-container a {
  height: 30px;
}

.nav__icon-container li a img {
  width: 30px;
  height: 30px;
  transition: all 0.2s;
}

.nav__icon-container li a img:hover {
  scale: 1.1;
  cursor: pointer;
  -webkit-transform: scale(1.05);
}

@media (max-width: 960px) {
  .logoContainer {
    height: 50%;
    order: -1;
  }
  .nav__link-container,
  .nav__icon-container {
    justify-content: space-between;
  }
  .nav__link-container li,
  .nav__icon-container li {
    margin: 0;
  }
  .nav__link-container a,
  .nav__icon-container a {
    margin: 0 !important;
  }
  .nav__link-container {
    margin: 1.25rem 0rem;
  }
  nav {
    height: 200px;
  }
  nav .wrapper {
    flex-direction: column;
    height: 100%;
  }
}
.wrapper {
  max-width: 90%;
  width: 1200px;
  margin: 0 auto;
}

h1 {
  text-align: center;
  margin: 1rem 0rem;
  font-style: italic;
  font-weight: 900;
  font-size: 42px;
}

.wrapper--flex-row {
  display: flex;
  align-items: center;
  height: 100%;
}

.wrapper--flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentBlock-wrapper {
  width: 100%;
  padding: 0.5rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  min-height: 650px;
}

.contentBlock-merch-wrapper {
  width: 100%;
  padding: 0.5rem 0rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.some-wrapper {
  padding: 2rem 0rem;
  background-color: #C995C8;
  width: 100%;
  box-shadow: 0px 0px 5px rgb(169, 168, 168);
}

.some-wrapper_top-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 90%;
  width: 1200px;
  margin: 1rem auto;
  padding: 2rem 0rem;
  border-radius: 3px;
  font-family: "Fira sans";
}
.some-wrapper_top-container h2 {
  color: #1E103B;
  font-family: "Fira sans";
  font-style: italic;
}
.some-wrapper_top-container p {
  color: #1E103B;
  margin-top: 1rem;
  max-width: 90%;
  text-align: center;
  font-family: "Fira sans";
}
.some-wrapper_top-container li {
  transition: all 0.2s;
  transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -webkit-transition: all 0.2s;
}

.some-wrapper__icon-container {
  display: flex;
  list-style: none;
  margin: 2rem 0rem 0.5rem 0rem;
}
.some-wrapper__icon-container img {
  width: 30px;
  height: 30px;
}

.some-container {
  display: grid;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.some-container img {
  object-fit: cover;
  height: 220px;
  width: 100%;
}

@media (max-width: 530px) {
  .some-wrapper__icon-container {
    width: 100%;
    justify-content: space-between;
    padding: 0rem 0.5rem;
  }
  .some-wrapper__icon-container a {
    margin: 0;
  }
}
.series-container {
  display: flex;
  width: 100%;
  background-color: #FFF24D;
  margin: 3rem 0rem;
  padding: 0.25rem;
  box-shadow: 5px 5px 0px #C995C8;
}

.series-container__image-container {
  width: 50%;
}
.series-container__image-container img {
  display: block;
  height: 275px;
  width: 100%;
  object-fit: cover;
}

.series-container__text-container {
  width: 75%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
}
.series-container__text-container p {
  color: rgb(0, 19, 97);
  margin-top: 1rem;
  font-family: "Fira sans";
  font-size: 18px;
}
.series-container__text-container h2 {
  font-style: italic;
  font-weight: 800;
}

.series-container__text-container__link-container {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
}
.series-container__text-container__link-container .series-container__youtube {
  width: 35px;
}
.series-container__text-container__link-container #youtube-link {
  border: none;
}
.series-container__text-container__link-container .youtube path {
  color: red;
}

.series-container__text-container__link-container__link {
  border: 3px solid #1E103B;
  border-radius: 5px;
  padding: 0.5rem 0.75rem;
  text-decoration: none;
  transition: all 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
}
.series-container__text-container__link-container__link svg {
  margin-left: 0.5rem;
}

#linkwrapper {
  margin: 0;
  display: inline;
}

.series-container__text-container__link-container__link:last-child svg {
  font-size: 20px;
}

.series-container__text-container__link-container__link:hover {
  scale: 1.05;
  transform: scale(1.05);
  -webkit-transform: scale(1.05);
  cursor: pointer;
}

@media (max-width: 780px) {
  .series-container {
    flex-direction: column;
  }
  .series-container__image-container {
    width: 100%;
  }
  .series-container__image-container img {
    height: auto;
  }
  .series-container__text-container {
    width: 100%;
  }
  .series-container__text-container__link-container {
    margin: 1rem 0rem;
    flex-direction: column;
  }
  .series-container__text-container__link-container__link {
    margin-bottom: 0.75rem;
  }
  .series-container__text-container__link-container__link:hover {
    scale: 1;
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
.show-container {
  width: 80%;
  margin: 0 auto;
  min-height: 100vh;
}
.show-container h1 {
  color: #1E103B;
}
@media (max-width: 500px) {
  .show-container h1 {
    font-size: 34px;
  }
}
.show-container h2 {
  margin: 4rem 0rem 3rem 0rem;
  color: #1E103B;
  font-style: italic;
  text-align: center;
  font-weight: 800;
}

.show-container__image-container {
  position: relative;
  width: 100%;
}
.show-container__image-container img {
  width: 100%;
  height: 500px;
  object-fit: cover;
}

.show-container__image-container__link {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: rgba(255, 255, 255, 0.537);
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
.show-container__image-container__link svg {
  height: 30px;
}

.show-container__image-container__link:hover {
  background-color: white;
}

.show-container__text-container p {
  margin-top: 1rem;
  font-family: "Fira sans";
  font-size: 18px;
  line-height: 1.75rem;
  color: black;
}
.show-container__text-container a {
  max-width: 300px;
  margin: 1.5rem auto;
  border: 2px solid #001361;
  border-radius: 3px;
  padding: 0.5rem 0.75rem;
  text-decoration: none;
  transition: all 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 5px rgb(107, 107, 107);
}
.show-container__text-container a:hover {
  scale: 1.1;
  cursor: pointer;
  -webkit-transform: scale(1.1);
}
.show-container__text-container svg {
  font-size: 25px;
}
.show-container__text-container .fa-instagram {
  margin-right: 0.5rem;
}
.show-container__text-container .fa-tiktok {
  margin-left: 0.5rem;
}
.show-container__text-container .show-container__text-container--icon {
  height: 35px;
}
.show-container__text-container .show-container__text-container__tiktok-container {
  margin: 2rem 0rem;
  background-color: #C995C8;
  display: flex;
  align-items: center;
  padding: 0.65rem;
}
.show-container__text-container .show-container__text-container__tiktok-container h6 {
  margin: 0rem 0rem 0rem 1rem;
  color: white;
}
.show-container__text-container .show-container__text-container__tiktok-container img {
  filter: invert(94%) sepia(100%) saturate(0%) hue-rotate(17deg) brightness(108%) contrast(102%);
}
.show-container__text-container .show-container__text-container__instagram-container {
  margin: 2rem 0rem;
  background-color: #30D197;
  display: flex;
  align-items: center;
  padding: 0.65rem;
}
.show-container__text-container .show-container__text-container__instagram-container h6 {
  margin: 0rem 0rem 0rem 1rem;
  color: white;
}
.show-container__text-container .show-container__text-container__instagram-container img {
  filter: invert(94%) sepia(100%) saturate(0%) hue-rotate(17deg) brightness(108%) contrast(102%);
}

.fa-youtube path {
  color: red;
}

.show-container__text-container--some {
  font-size: 30px !important;
  font-weight: 600;
  text-align: center;
  margin: 2.5rem 0rem !important;
  font-style: italic;
  color: #743292 !important;
}
.show-container__text-container--some .fa-tiktok path {
  color: #743292;
}
.show-container__text-container--some .fa-instagram path {
  color: #743292;
}

.show-container__demography-container {
  padding: 0.25rem;
  margin-bottom: 5rem;
}
.show-container__demography-container p {
  font-family: "Fira sans";
  font-size: 18px;
}

.demography-container__youtube-container {
  display: flex;
  padding: 1rem;
  justify-content: space-evenly;
  align-items: center;
  background-color: #F92C79;
}
.demography-container__youtube-container img {
  width: 135px;
  height: 40px;
}
.demography-container__youtube-container p, .demography-container__youtube-container strong {
  color: white;
}

.youtube-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

#demography {
  text-align: center;
}

#youtube-logo {
  width: 120px;
  margin: 0 auto;
}

.demography-container__youtube-container p {
  margin: 0rem 1rem 0rem 1rem;
}

@media (max-width: 960px) {
  .show-container h1 {
    margin-top: 2.5rem;
  }
}
@media (max-width: 900px) {
  .demography-container__youtube-container {
    flex-direction: column;
  }
  .demography-container__youtube-container p {
    margin-top: 0.75rem;
  }
}
@media (max-width: 700px) {
  .show-container__image-container {
    height: 200px;
  }
  .show-container__image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  #demography,
  .graph-container {
    display: none !important;
  }
  .show-container__text-container a {
    margin: 2.5rem auto;
  }
  .demography-container__youtube-container p {
    margin: 0.5rem auto;
  }
}
.graph-container {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 50px repeat(6, 1fr);
  grid-gap: 20px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 1rem;
}
.graph-container ul {
  height: 100%;
  width: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-right: 1px solid #ccc;
}
.graph-container li:last-child {
  margin-bottom: 1rem;
}
.graph-container .graph-container__indicator {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.graph-container__item {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}
.graph-container__item p:first-of-type {
  color: #F92C79;
  font-weight: bold;
  font-size: 24px;
}

.graph-container__item__column {
  background-color: #F92C79;
  width: 50%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.graph-container li,
.graph-container__item p {
  color: black;
}

.ages {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 50px repeat(6, 1fr);
  grid-gap: 20px;
  justify-items: center;
  margin-bottom: 3rem;
}
@media (max-width: 700px) {
  .ages {
    display: none;
  }
}
.ages p {
  text-align: center;
  align-self: end;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(255, 255, 255, 0.2);
  border-right: 1.1em solid rgba(255, 255, 255, 0.2);
  border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
  border-left: 1.1em solid #868686;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.om-loff {
  max-width: 800px;
  margin: 0 auto;
  min-height: 100vh;
}
.om-loff p {
  margin-bottom: 1.5rem;
}
.om-loff img {
  width: 100%;
  margin-bottom: 1.5rem;
}

.om-loff--bold-center {
  font-weight: bold;
  text-align: center;
  margin: 3rem 0rem !important;
}

.dropdown {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.dropdown #dropdown--heading {
  margin-top: 1.5rem;
}
.dropdown .dropdown__top-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: fit-content;
  padding: 1rem 0.5rem;
  background-color: #FFF24D;
  border: 2px solid #1E103B;
}
.dropdown .dropdown__top-container__arrow {
  position: absolute;
  right: 10px;
  font-size: 25px;
  cursor: pointer;
}
.dropdown .dropdown__top-container__arrow :hover {
  transition: all 0.2s;
  transform: translateY(5px);
}
.dropdown .dropdown__container {
  background-color: #FFF24D;
  padding: 2rem 1rem;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-bottom: 2rem;
}
.dropdown .heading--centered {
  text-align: center;
  margin-bottom: 2rem;
}
@keyframes append-animate {
  from {
    transform: scaleY(0);
    opacity: 0;
  }
  to {
    transform: scaleY(1);
    opacity: 1;
  }
}
.dropdown .dropdown__container {
  transform-origin: 50% 0;
  animation: append-animate 0.3s linear;
}

.sponsor-container {
  display: flex;
  margin: 2rem 0rem;
}
.sponsor-container .sponsor-container__image-container {
  background-color: white;
}
.sponsor-container .sponsor-container__image-container img {
  height: 200px;
  width: 200px;
  object-fit: contain;
  padding: 0.5rem;
}
.sponsor-container .sponsor-container__text-container {
  display: flex;
  flex-direction: column;
  background-color: #FFF24D;
  flex-grow: 1;
  padding: 1rem;
  color: #743292;
}
.sponsor-container .sponsor-container__text-container p {
  margin: 0.5rem 0rem;
}
.sponsor-container .sponsor-container__text-container img {
  height: 35px;
}
.sponsor-container .sponsor-container__text-container__sponsor-info {
  display: flex;
  justify-content: space-between;
  margin-top: auto;
}
.sponsor-container .sponsor-container__text-container__sponsor-info div {
  display: flex;
  align-items: center;
}
.sponsor-container .sponsor-container__text-container__sponsor-info div h6 {
  margin: 0rem 0rem 0rem 0.25rem;
}

.checkboxEmpty {
  height: 35px;
  width: 35px;
}

@media (max-width: 1050px) {
  .sponsor-container {
    flex-direction: column;
  }
  .sponsor-container__image-container {
    align-self: center;
    margin-bottom: 1rem;
  }
}
@media (max-width: 760px) {
  .sponsor-container__text-container__sponsor-info {
    flex-direction: column;
  }
  .sponsor-container__text-container__sponsor-info div {
    width: 181px;
    margin: 0.25rem 0rem;
  }
}
@media (max-width: 700px) {
  #youtube-logo {
    width: 150px;
    margin: 2rem 0rem 2rem 0rem;
  }
}
.some-wrapper__icon-container .some-link {
  margin: 0rem 1rem 0rem 1rem;
}

.some-link img {
  transition: all 0.2s;
}
.some-link img:hover {
  scale: 1.1;
  cursor: pointer;
  -webkit-transform: scale(1.1);
}

