.bassene-container {
    display: grid;
    grid-template-rows: auto auto auto;
    grid-template-columns: 1fr 1fr;
    gap: 22px;
}

.subheading {
    text-align: center;
    font-style: italic;
    margin-bottom: 1rem;
}

.employeeCard {
    width: 100%;
    margin: 2rem 0rem;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    box-shadow: 5px 5px 0px map-get($colors, backgroundPurple );
    background-color: map-get($colors, myYellow );
}
.employeeCard__text-container {
    display: flex;
    flex-direction: column;
    h4 {
        margin: 0.5rem 0rem;
    }
    h5 {
        margin-bottom: 0.5rem;
        font-style: italic;
        font-weight: 500;
    }
    p {
        font-family: map-get($fonts, body);
        font-weight: 500;
        margin-bottom: 1rem;
    }
}

.employeeCard__image-container {
    height: 60%;
    height: 400px;
    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: -0px -80px;
    }
}

.employeeCard__social-container {
    display: flex;
    margin-top: auto;
}

.employeeCard__social-container__icon {
    height: 30px;
    margin-right: 1rem;
    transition: all 0.2s;
}
.employeeCard__social-container__icon:hover {
    scale: 1.1;
}

@media (max-width: 970px) {
    .bassene-container {
        grid-auto-rows: auto !important;
        grid-template-columns: 1fr;
    }
    .employeeCard {
        height: fit-content;
        max-width: 550px;
        margin: 0 auto;
    }
    .employeeCard__text-container {
        margin-bottom: 1rem;
    }
    .employeeCard__social-container {
        // p {
        //     margin: 0.5rem auto;
        // }
    }
}

@media screen and (max-width: 750px) {
    .bassene-container {
        grid-template-rows: auto;
        grid-template-columns: 1fr;
    }
}